import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormControlNameExport } from './form-control-name-export.directive';

@NgModule({
  declarations: [FormControlNameExport],
  imports: [CommonModule],
  exports: [FormControlNameExport],
})
export class DirectivesModule {}
